import Vue from 'vue';
import Cerebro from '@/client';

import router from '../../router';

import store from 'store';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    client: new Cerebro(),
    user: {},
    blogPosts: [],
    authenticated: false,
    showMobileSidebar: false,
    languages: [
      { name: 'arabic', current: 0 },
      { name: 'chinese', current: 0 },
      { name: 'dutch', current: 0 },
      { name: 'english', current: 1, currentLang: 1 },
      { name: 'french', current: 0 },
      { name: 'german', current: 0 },
      { name: 'italian', current: 0 },
      { name: 'portuguese', current: 0 },
      { name: 'russian', current: 0 },
      { name: 'spanish', current: 0 },
    ],
    countries: [],
    toasts: {},
    orders: [],
    userGeo: [],
    isGeoLocationScriptLoaded: false,
  },
  mutations: {
    set: (state, payload) => {
      Object.keys(payload).forEach((key) => {
        Vue.set(state, key, payload[key]);
      });
    },
    deleteSaleOrder: (state, ordernumber) => {
      const orderIndex = _.findIndex(state.orders, {
        order_number: ordernumber,
      });
      if (orderIndex === -1) return;
      state.orders.splice(orderIndex, 1);
    },
  },
  actions: {
    async init(context) {
      await context.dispatch('getSystemStatus');
      await context.dispatch('getCountries');
      await context.dispatch('authenticate');

      if (
        context.state.user &&
        context.state.user.profilecomplete !== 1 &&
        context.state.user.istrialeligible === 1 &&
        context.state.user.producttype !== 1 &&
        context.state.user.iscommunityedition !== 1
      ) {
        await this.dispatch('sites/startFreeTrial', {
          trialtype: 0,
        });
      }
    },

    async getCountries(context) {
      const response = await context.state.client.get('core/getcountries');

      if (response.ok) {
        context.commit('set', { countries: response.data.country });
      }
    },

    async showMobileSidebar(context, payload) {
      context.commit('set', { showMobileSidebar: payload });
    },

    async getSystemStatus(context) {
      const response = await context.state.client.get('admin/getsystemstatus');

      if (response.ok) {
        context.commit('set', { system: response.data.status });
      }
    },
    async authenticate(context, settings) {
      // authenticate via token
      if (router.history.current.query?.auth) {
        const authResponse = await context.dispatch('resumeSession', {
          token: router.history.current.query.auth,
        });

        if (authResponse.ok) {
          return;
        }
      }

      const response = await context.state.client.get('core/getauthstatus');

      if (response.ok) {
        await context.dispatch('updateUserInfo');

        context.commit('set', { authenticated: true });

        if (!settings || !settings.noContent) {
          // fetch content
          context.dispatch('getBlog');

          this.dispatch('sites/getSites');
          this.dispatch('checkout/getProducts', null, { root: true });
        }

        // if user is in a unauthenticated only page
        if (
          router.history.current.meta.unauthenticated &&
          !settings?.noRouter
        ) {
          router.push('/', () => context.commit('set', { ready: true }));
          return;
        }

        context.commit('set', { ready: true });
      } else {
        // if on an authenticated page
        if (router.history.current.meta.authenticated && !settings?.noRouter) {
          router.push('/login', () => context.commit('set', { ready: true }));
          return;
        }

        context.commit('set', { ready: true });
      }
    },

    async updateUserInfo(context) {
      // get user info
      const userResponse = await context.state.client.get(
        'core/getuserdetails'
      );

      if (userResponse.ok && userResponse.data?.user) {
        context.commit('set', { user: userResponse.data.user });
      }
    },
    async verifyGuest(context, email) {
      const response = await context.state.client.post('core/verifyguest', {
        userid: email,
      });
      if (response.data?.exists) {
        const { haspassword, displayname: customername } = response.data;

        context.commit('set', {
          user: {
            email,
            customername,
            haspassword,
          },
        });
      }

      return response;
    },

    async login(context, { userid, password, code }) {
      let payload = {
        userid,
        ...(password !== undefined && { password }),
        ...(code !== undefined && { code }),
      };

      const response = await context.state.client.post(
        'core/loginguest',
        payload
      );

      if (response.ok) {
        context.dispatch('authenticate');
      }

      return response;
    },

    async logout(context) {
      context.state.client.post('core/logoutprofile');

      context.commit('set', { user: {}, authenticated: false });
      router.push('/login');
    },

    async resendCode(context, email) {
      const response = await context.state.client.post('core/verifyguest', {
        userid: email,
      });
      return response.data?.timeout;
    },

    async forgotPassword(context, email) {
      const response = await context.state.client.post('core/forgotpassword', {
        emailid: email,
      });
      return response;
    },

    async resumeSession(context, payload) {
      const response = await context.state.client.post('core/resumesession', {
        token: payload.token,
      });

      if (response.ok) {
        await context.dispatch('authenticate', {
          ...payload.settings,
          noRouter: true,
        });
      }

      return response;
    },

    async trialSignup(context, payload) {
      const utmParams = store.get('utm_params') ? store.get('utm_params') : {};

      const communityFlag = payload.iscommunityedition ? '?community=true' : '';

      if (!payload.iscommunityedition) {
        delete payload.usagetype;
      }

      const response = await context.state.client.post(
        `admin/addpendingrecord${communityFlag}`,
        { ...payload, ...utmParams }
      );
      if (response.ok) {
        context.commit('set', { user: payload });
        store.remove('utm_params');
      }
      return response;
    },

    async trialConfirm(context, payload) {
      const response = await context.state.client.post(
        'admin/confirmrecord',
        payload
      );
      return response;
    },

    async updateProfile(context, payload) {
      if (payload.password === '') delete payload.password;
      if (payload.industry === 'Online') delete payload.industry;
      if (payload.purchasetimeframe === 'Online')
        delete payload.purchasetimeframe;

      const communityFlag = payload.iscommunityedition ? '?community=true' : '';

      const response = await context.state.client.post(
        `core/updatecustomerprofile${communityFlag}`,
        payload
      );

      if (response.ok) {
        context.commit('set', {
          user: {
            ...context.state.user,
            ...payload,
            profilecomplete: 1,
          },
        });
      }

      return response;
    },

    async updatePassword(context, payload) {
      const response = await context.state.client.post(
        'core/updatepassword',
        payload
      );

      if (response.ok) {
        context.commit('set', {
          user: {
            ...context.state.user,
            profilecomplete: 1,
            haspassword: 1,
          },
        });
      }

      return response;
    },

    async getBlog(context) {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 0);
      });

      const response = await context.state.client.get(
        'core/getblogfeed',
        {},
        'useJson'
      );

      if (response.ok && response.data.record?.length > 0) {
        context.commit('set', { blogPosts: response.data.record });
      }

      return response.data.record;
    },

    async getBillingInfo(context) {
      const response = await context.state.client.get('core/getsaleorders');

      if (
        response.ok &&
        response.data.saleorder &&
        response.data.meta?.total > 0
      ) {
        const { saleorder } = response.data;
        const orders = Array.isArray(saleorder) ? saleorder : [saleorder];

        context.commit('set', { orders });
      }

      return response;
    },

    async deleteSaleOrder(context, ordernumber) {
      const response = await context.state.client.get('core/deletesaleorder', {
        ordernumber,
      });

      if (response.ok) {
        context.commit('deleteSaleOrder', ordernumber);
        await context.dispatch('getBillingInfo');
      } else {
        Vue.$toast.error('Failed to delete order.');
      }

      return response;
    },

    async getDownloads(context) {
      const response = await context.state.client.get(
        'core/getconfigsetting',
        {
          count: 28,
          param0: 'CEREBRO_WINDOWS_SERVER',
          param1: 'CEREBRO_LINUXDEB_SERVER',
          param2: 'CEREBRO_LINUXRPM_SERVER',
          param3: 'CEREBRO_OVF_SERVER',
          param4: 'CEREBRO_AWS_SERVER',
          param5: 'CEREBRO_AZURE_SERVER',
          param6: 'CEREBRO_DOCKER_SERVER',
          param7: 'CEREBRO_WINDOWS_SYNCCLIENT',
          param8: 'CEREBRO_LINUX_SYNCCLIENT',
          param9: 'CEREBRO_MAC_SYNCCLIENT',
          param10: 'CEREBRO_WINDOWS_DRIVE_CLIENT',
          param11: 'CEREBRO_MAC_DRIVE_CLIENT',
          param12: 'CEREBRO_IOS_CLIENT',
          param13: 'CEREBRO_ANDROID_CLIENT',
          param14: 'CEREBRO_WIN8_CLIENT',
          param15: 'CEREBRO_WINRT_CLIENT',
          param16: 'CEREBRO_OUTLOOK_PLUGIN',
          param17: 'CEREBRO_OFFICE_PLUGIN',
          param18: 'CEREBRO_NTFS_HELPER',
          param19: 'CEREBRO_AUTOMATION_APP',
          param20: 'CEREBRO_BACKUPSERVER_ZIP',
          param21: 'CEREBRO_SERVER_SYNC',
          param22: 'CEREBRO_WINDOWS_SYNC2CLIENT',
          param23: 'CEREBRO_LINUX_SYNC2CLIENT',
          param24: 'CEREBRO_MAC_SYNC2CLIENT',
          param25: 'CEREBRO_WINDOWS_SECUREVIEWER',
          param26: 'CEREBRO_MAC_SECUREVIEWER',
          param27: 'CEREBRO_MAC_DESKTOP',
          param28: 'CEREBRO_WINDOWS_DESKTOP',
        },
        'useJson'
      );

      return response;
    },
    async submitemail(context, payload) {
      const response = await context.state.client.post(
        'core/submitemail',
        payload
      );

      return response;
    },
    sendAnalyticsEvent(context, { category, action, label }) {
      if ('gtag' in window) {
        // send google analytics signup event
        window.gtag('event', action, {
          event_category: category,
          event_label: label,
        });

        window.gtag('event', 'signup', {
          hitType: 'event',
          eventCategory: category,
          eventAction: action,
          eventLabel: label,
        });
      }
      if ('_paq' in window) {
        window._paq.push(['trackEvent', category, action, label]);
      }
    },
    async getUserGeo(context) {
      const response = await context.state.client.get('admin/getusergeo');
      if (response.ok) {
        context.commit('set', { userGeo: response.data });
      }
    }
  },
  getters: {
    getCountryEntity: (state) => {
      return state.user.billing_entity;
    },
    showDownloadSection: (state) => {
      let trialStarted = (state.user.istrialstarted || state.user.isonlinetrialstarted || state.user.total_sales);
      return (trialStarted) || (!state.user.isassistedtrial);
    }
  },
};
